import React from "react"

function DownChevron({
  className,
}: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 60.8 32.9"
      style={{
        enableBackground: `new 0 0 60.8 32.9`,
      }}
      xmlSpace="preserve"
    >
      <defs>
        <path id="a" d="M0 0h60.8v32.9H0z" />
      </defs>
      <clipPath id="b">
        <use
          xlinkHref="#a"
          style={{
            overflow: `visible`,
          }}
        />
      </clipPath>
      <path
        style={{
          clipPath: `url(#b)`,
          fill: `none`,
          stroke: `#65a834`,
          strokeWidth: 1.6,
        }}
        d="M.6 3.4 29 31.8 60.2.6"
      />
    </svg>
  )
}

export default DownChevron
