import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import Welcome from "../components/welcome"
import MainLayout from "../layouts/main"

const Default: React.FC<PageProps> = () => (
  <MainLayout showNotifications={false}>
    <main id="main">
      <Welcome />
    </main>
  </MainLayout>
)

let IndexPage: React.FC<PageProps> = Default
const EventPage: React.FC<PageProps> = React.lazy(() => import(`./event`))

switch (process.env.GATSBY_LANDING_PAGE) {
  case `event`:
    IndexPage = EventPage
    break
  default:
    IndexPage = EventPage
}

export default IndexPage

export const Head: HeadFC = () => (
  <title>Georgian College OCCCIO Conference</title>
)
